<template>
  <div class="classification-requests-view mt-2">
    <b-overlay
      :show="loading"
      rounded
      no-center
      opacity="0.7"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block w-100"
    >
      <header-classification-requests
        :classification-requests="classificationRequests"
        :classification-requests-meta="classificationRequestsMeta"
        @change-items="handleChangeItems"
      />

      <table-classification-requests
        :classification-requests="classificationRequests"
        :classification-requests-meta="classificationRequestsMeta"
        @change-items="handleChangeItems"
      />
      <template v-slot:overlay>
        <div class="text-center loading-spinner">
          <b-spinner style="width: 3rem; height: 3rem;" variant='primary' label="Large Spinner"></b-spinner>
          <h5 class='text-primary'>Buscando...</h5>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import TableClassificationRequests from '@/components/ClassificationRequests/Table'
import HeaderClassificationRequests from '@/components/ClassificationRequests/Header'
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ClassificationRequests',
  components: {
    HeaderClassificationRequests,
    TableClassificationRequests
  },
  computed: {
    ...mapGetters('classificationRequests', {
      classificationRequests: 'getClassificationRequests',
      classificationRequestsMeta: 'getClassificationRequestsMeta',
    }),
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    ...mapActions('classificationRequests', {
      handleGetClassificationRequests: 'handleGetClassificationRequests',
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),
    async handleChangeItems(payload){
      this.loading = true;
      const params = {
        ...this.classificationRequestsMeta,
        ...payload,
      }

      await this.handleGetClassificationRequests(params);
      this.loading = false;
    }
  },
  async created(){
    await this.handleChangeItems({ sortIn: 'desc'})
    const breadcrumb = {
      page: 'Pedidos de classificação',
      subPage: {
        name: 'Pedidos de classificação',
        active: true
      },
      tabs: []
    }
    await this.setBreadcrumb(breadcrumb)
  },
}
</script>

<style lang="scss" scoped>
.classification-requests-view {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.loading-spinner{
  margin-top: 250px;
}
</style>
