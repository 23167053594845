<template>
  <base-table
    v-if="classificationRequests && classificationRequestsMeta"
    :columns="columns"
    :data="classificationRequests"
    :total-items="classificationRequestsMeta.total"
    :start-items="classificationRequestsMeta.skip + 1"
    :max-items="classificationRequestsMeta.limit"
    @set-page="handleChangeItems"
  >
    <template v-slot:body-createdAt="row">
      {{formatDate(row.item.createdAt)}}
    </template>
  </base-table>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'TableClassificationRequests',
  data(){
    return {
      columns: [
        { key: 'cas', value: 'CAS' },
        { key: 'userEmail', value: 'Usuário' },
        { key: 'createdAt', value: 'Data' },
      ]
    }
  },
  props: {
    classificationRequests: { type: Array, required: true },
    classificationRequestsMeta: { type: Object, required: true },
  },
  methods: {
    formatDate(date){
      return format(new Date(date), 'dd/MM/yyyy')
    },
    handleChangeItems(page){
      const params = {
        skip: (page - 1) * this.classificationRequestsMeta.limit
      }
      this.$emit('change-items', params);
    }
  },
}
</script>