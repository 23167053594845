<template>
  <div class='mb-3'>
    <b-modal
      ref='alert'
      scrollable
      hide-footer
      title='Atenção'
      header-bg-variant='info'
      header-text-variant='light'
    >
      <section class='modal-font-family'>
        <p>
          A impressão do arquivo em Excel esta sendo processada,
          clique no botão abaixo para acessar a página de downloads.
        </p>

        <router-link class='float-right router-link-text-decoration' :to="{ name: 'RelatoriosConsultaLegal' }">
          <base-button class='btn_export_modal'>
            <span class="label">Acessar</span>
          </base-button>
        </router-link>
      </section>
    </b-modal>
    <b-alert
      show
      variant='danger'
      :class='{ hidden: !errors }'
    >
      {{ errors }}
    </b-alert>
    <header>
      <base-input-text
        class='mr-1'
        label='Início'
        v-model='startDate'
        type='date'
      />

      <base-input-text
        class='mr-1'
        v-model='endDate'
        type='date'
        label='Final'
      />

      <base-input-text
        class='mr-1'
        block
        v-model='filter'
        placeholder='Pesquisar por email'
        type='text'
      />

      <base-button
        class='btn-pesquisar mr-1'
        @click='handleChangeItems'
        :disabled='!isValid()'
      >
        Pesquisar
      </base-button>

      <base-button
        class='btn-limpar-filtros mr-1'
        @click='resetFilters()'
      >
        Limpar filtros
      </base-button>

      <base-button @click='handleDownloadXlsx' class='btn-export mr-1' :disabled="exportingXLSX || !datesIsValid()">
        <section v-if='exportingXLSX' class='d-flex align-items-center justify-content-center'>
          <b-spinner
            class='loading-button'
            color='#FFFFFF'
            label="Spinning">
          </b-spinner>
          <span class='ml-2'>Exportando...</span>
        </section>

        <section v-else>
          <i class='fas fa-download' />
          Exportar em XLSX
        </section>
      </base-button>

      <base-button @click='handleDownloadCsv' class='btn-export' :disabled="exportingCSV || !datesIsValid()">
        <section v-if='exportingCSV' class='d-flex align-items-center justify-content-center'>
          <b-spinner
            class='loading-button'
            color='#FFFFFF'
            label="Spinning">
          </b-spinner>
          <span class='ml-2'>Exportando...</span>
        </section>
        <section v-else>
          <i class='fas fa-download' />
          Exportar em CSV
        </section>
      </base-button>
    </header>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'HeaderClassificationRequests',
  props: {
    classificationRequests: { type: Array, required: true },
    classificationRequestsMeta: { type: Object, required: true }
  },
  data() {
    return {
      maximumIntervalBetweenDatesInMonths: 3.1,
      exportingXLSX: false,
      exportingCSV: false,
      startDate: '',
      endDate: '',
      filter: '',
      downloadType: ''
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    },
    errors() {
      return this.$store.getters['access/error']
    }
  },
  async mounted(){
    this.endDate  = moment(new Date()).format('YYYY-MM-DD')
    this.startDate = moment(new Date()).add(-3, 'months').format('YYYY-MM-DD')
    await this.$store.dispatch('access/updateError', '')
  },
  methods: {
    ...mapActions('classificationRequests', {
      handleGetClassificationRequestsExport: 'handleGetClassificationRequestsExport'
    }),
    ...mapActions('reportsDownloads', {
      handleCreateReportDownloads: 'handleCreateReportDownloads',
    }),
    isValid() {
      return ((this.startDate && this.endDate) || this.filter)
    },
    async handleChangeItems() {
      const params = {
        startDate: this.startDate ? new Date(this.startDate).toISOString().split('T')[0] : '',
        endDate: this.endDate ? new Date(this.endDate).toISOString().split('T')[0] : '',
        filter: this.filter
      }

      this.$emit('change-items', params)
    },
    resetFilters() {
      this.filter = ''
      this.$emit('change-items', {})
    },
    async handleDownload(limit) {
      const params = {
        limit
      }

      this.$emit('change-items', params)
    },
    async handleDownloadXlsx() {
      if(this.dateRangeIsValid()) {
        if (moment(new Date(this.filter.startDate)).isAfter(new Date(this.filter.endDate))) {
          await this.$store.dispatch('access/updateError', `A data de início não pode ser posterior a da final.`)
          return
        }
        this.exportingXLSX = true;
        this.downloadType = 'classificationRequestsxlsx';
        await this.generateReport();
        return
      }
      await this.$store.dispatch('access/updateError', `O intervalo entre as datas de início e final é de no máximo de 3 meses.`)
    },
    async handleDownloadCsv() {
      if(this.dateRangeIsValid()) {
        if (moment(new Date(this.filter.startDate)).isAfter(new Date(this.filter.endDate))) {
          await this.$store.dispatch('access/updateError', `A data de início não pode ser posterior a da final.`)
          return
        }
        this.exportingCSV = true;
        this.downloadType = 'classificationRequestscsv'
        await this.generateReport()
        return
      }
      await this.$store.dispatch('access/updateError', `O intervalo entre as datas de início e final é de no máximo de 3 meses.`)
    },
    datesIsValid() {
      return this.startDate && this.endDate;
    },
    dateRangeIsValid(){
      return this.datesIsValid() && moment.duration(moment(new Date(this.endDate)).diff(moment(new Date(this.startDate)))).asMonths() <= this.maximumIntervalBetweenDatesInMonths
    },
    async generateReport() {
      const payload = {
        user: this.user.id,
        type: this.downloadType,
        job: 'classificationRequestsReport',
        format: this.downloadType === 'classificationRequestsxlsx' ? 'xlsx' : 'csv',
        parameters: {
          startDate: this.startDate,
          endDate: this.endDate,
          filter: this.filter
        }
      }
      const { data, status } = await this.handleCreateReportDownloads(payload)

      let message = data.message
      let variant = 'danger'
      this.exportingXLSX = false;
      this.exportingCSV = false;
      if(status === 201){
        message = data.message;
        variant = 'success';
        this.$refs['alert'].show()
        return
      }
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      });
      this.downloadType = ''
    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .inputs {
    display: flex;
    align-items: flex-end;
    gap: 8px;

    span {
      font-weight: bold;
    }
  }

  .export-buttons {
    display: flex;
    align-items: center;
    gap: 8px;

    .btn-export {
      height: 48px;
    }
  }

  .btn-export {
    min-width: 190px;
    height: 48px;
  }

  .btn-pesquisar {
    height: 48px;
  }

  .btn-limpar-filtros {
    min-width: 135px;
    height: 48px;
  }
}
</style>
